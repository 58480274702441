<template>
  <div class="home">
  </div>
</template>

<script>
// @ is an alias to /src
import { getLanguage } from '@/lang'
export default {
  name: 'Home',
  components: {},
  created() {
    const lang = getLanguage()
    if (lang === 'zh') {
      this.$router.push({ name: 'MiYouZh' })
    } else {
      this.$router.push({ name: 'MiYouEn' })
    }
  }
}
</script>
